<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Envios de material </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Ventas pendientes de envio" icon="file-send-outline">
        <SaleDeliveriesList :type="0" />
      </b-tab-item>
      <b-tab-item
        label="Ventas enviadas completamente"
        icon="send-check-outline"
      >
        <SaleDeliveriesList :type="1" />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import SaleDeliveriesList from "../components/SaleDeliveriesList";

export default {
  name: "AllDeliveriesList",
  components: {
    SaleDeliveriesList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "salesDeliveries");
  },
  async created() {
    let allPromises = [];
    allPromises.push(
      this.$store.dispatch("GETSALESWITHOUTSALES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        endDate: new Date(),
        warehouses: [],
      })
    );
    allPromises.push(
      this.$store.dispatch("GETQUOTES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        endDate: new Date(),
        warehouses: [],
      })
    );
    allPromises.push(this.$store.dispatch("GETDISCOUNTCONCEPTS"));
    if (this.$store.getters.COMMERCIALBUSINESS.length === 0) {
      allPromises.push(this.$store.dispatch("GETCOMMERCIALBUSINESS"));
    }
    if (this.$store.getters.SATCFDIUSES.length === 0) {
      allPromises.push(this.$store.dispatch("GETSATCFDIUSES"));
    }
    if (this.$store.getters.PAYMENTTYPES.length === 0) {
      allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
    }
    if (this.$store.getters.SATREGIMENES.length === 0) {
      allPromises.push(this.$store.dispatch("GETSATREGIMENES"));
    }
    if (this.$store.getters.PACKAGINGS.length === 0) {
      allPromises.push(this.$store.dispatch("GETPACKAGINGS"));
    }
    if (this.$store.getters.TRANSPORTATIONPERMISSIONS.length === 0) {
      allPromises.push(this.$store.dispatch("GETTRASNPORTATIONPERMISSIONS"));
    }
    allPromises.push(this.$store.dispatch("GETVEHICULES"));
    if (this.$store.getters.TRANSPORTATIONCONFIGURATION.length === 0) {
      allPromises.push(this.$store.dispatch("GETTRANSPORTATIONCONFIGURATION"));
    }
    if (this.$store.getters.TRAILERTYPES.length === 0) {
      allPromises.push(this.$store.dispatch("GETTRAILERTYPE"));
    }
    if (this.$store.getters.WEIGHTUNITS.length === 0) {
      allPromises.push(this.$store.dispatch("GETWEIGHTUNITS"));
    }
    if (this.$store.getters.DANGEROUSMATERIALS.length === 0) {
      allPromises.push(this.$store.dispatch("GETDANGEROUSMATERIALS"));
    }
    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
